@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  .ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-base-border);
  }
}

*,
::before,
::after {
  border: none;
}

[type='checkbox'],
[type='radio'] {
  appearance: auto;
}

hr {
  border-top-style: solid;
  border-top-color: rgb(229 229 229);
}

select:not([size]) {
  padding-right: 2rem;
}

.border,
.border-2,
.border-4 {
  border-style: solid;
}

.border-l {
  border-left-style: solid;
}

.lg\:even\:border-l {
  border-left-style: solid;
}

.lg\:border-l {
  border-left-style: solid;
}

.border-t {
  border-top-style: solid;
}

.border-r {
  border-right-style: solid;
}

.border-b,
.border-b-2,
.border-b-4 {
  border-bottom-style: solid;
}

.border-x {
  border-left-style: solid;
  border-right-style: solid;
}

.border-y {
  border-top-style: solid;
  border-bottom-style: solid;
}

body {
  color: rgb(var(--foreground-rgb));
  background: #fff;
}

@font-face {
  font-family: sermonpastor;
  src: url(/fonts/sermonpastor.eot?udiqng);
  src:
    url(/fonts/sermonpastor.eot?#iefixudiqng) format('embedded-opentype'),
    url(/fonts/sermonpastor.woff?udiqng) format('woff'),
    url(/fonts/sermonpastor.ttf?udiqng) format('truetype'),
    url(/fonts/sermonpastor.svg?udiqng#sermonpastor) format('svg');
  font-weight: 400;
  font-style: normal;
}

[class*=' icon-'],
[class^='icon-'] {
  font-family: sermonpastor;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-man:before {
  content: '\e600';
}

.icon-Facebook:before {
  content: '\e602';
}

.icon-GooglePlus:before {
  content: '\e603';
}

.icon-heart3:before {
  content: '\e604';
}

.icon-logo:before {
  content: '\e605';
}

.icon-Photo:before {
  content: '\e606';
}

.icon-Purchase:before {
  content: '\e607';
}

.icon-Sermons:before {
  content: '\e608';
}

.icon-Twitter:before {
  content: '\e609';
}

.icon-Video:before {
  content: '\e60a';
}

.icon-menu:before {
  content: '\e60b';
}

.icon-youtube:before {
  content: '\e60c';
}

.icon-down:before {
  content: '\e60d';
}

.icon-share:before {
  content: '\e60e';
}

.icon-heart:before {
  content: '\e60f';
}

.icon-heart2:before {
  content: '\e610';
}

.icon-search:before {
  content: '\e611';
}

.icon-menu1:before {
  content: '\e612';
}

.icon-menu2:before {
  content: '\e613';
}

.icon-menu3:before {
  content: '\e614';
}

.icon-play:before {
  content: '\e615';
}

.icon-SermonsLogoText:before {
  content: '\e617';
}

.icon-SermonsLogo:before {
  content: '\e618';
}

.sermon-content p:has(~ *) {
  margin-bottom: 0;
}

.sermon-content p:has(~ p) {
  margin-bottom: 1rem;
}

.sermon-content a {
  color: rgb(29 106 189);
}

.sermon-content a:hover {
  text-decoration: underline;
}

.ck.ck-content:not(.ck-comment__input *),
.ck-source-editing-area {
  height: 100%;
  overflow-y: auto;
}

.ck-source-editing-area > textarea {
  height: 100% !important;
  overflow-y: auto !important;
}

.modal-open {
  overflow: hidden;
}

.ck-content hr {
  background: #dedede;
  border: 0;
  height: 1px !important;
  margin: 0 !important;
}

.image-style-side {
  float: right;
  max-width: 50%;
}

.image.image-style-side img {
  float: right;
}

.table {
  margin: 0 auto;
}

.table > table > tbody > tr > td {
  border: solid 1px black;
  min-width: 2em;
  padding: 0.4em;
}

.ck-powered-by-balloon {
  display: none !important;
}

.grey-text .ck-editor {
  color: #808080;
}

table.ck-table-resized {
  width: 100%;
}

.text-huge {
  font-size: 1.8em;
}

.text-big {
  font-size: 1.4em;
}

.text-small {
  font-size: 0.85em;
}

.text-tiny {
  font-size: 0.7em;
}

blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.sermon-content ol {
  list-style: decimal;
  list-style-position: inside;
}

.sermon-content ul {
  list-style: disc;
  list-style-position: inside;
}

.ck-list-bogus-paragraph {
  display: inline !important;
}

*:has(> .overflow-hidden > [aria-label]) {
  position: relative;
}

[aria-label]::after {
  display: block;
  height: auto;
  content: attr(aria-label);
  position: absolute;
  left: -0.125rem;
  bottom: calc(100% + 0.25rem);
  /* put it on the top */
  color: #000;
  background-color: rgb(250 250 250);
  border: 0.5px solid black;
  border-radius: 0.5rem;
  width: max-content;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  padding: 0.125rem 0.25rem;
  pointer-events: none;
  user-select: none;
  line-height: 1;
  box-shadow: 0px 3px 3px 0px rgb(212 212 212);
  z-index: 40;
}

[aria-label].down::after {
  bottom: calc(100% - 0.635rem);
  left: 0.25rem;
}

[aria-label]:hover:after {
  opacity: 1;
}
